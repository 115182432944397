//
// Chat.scss
//

.chat-leftsidebar {
    @media (min-width: 992px) {
        min-width: 260px;
    }

    @media (min-width: 1200px) {
        min-width: 380px;
    }

    .chat-leftsidebar-nav {
        .nav {
            background-color: var(--#{$prefix}secondary-bg);
        }

        .tab-content {
            min-height: 488px;
        }
    }
}

.chat-noti-dropdown {
    &.active {
        &:before {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: $danger;
            border-radius: 50%;
            right: 0;
        }
    }

    .btn {
        padding: 6px;
        box-shadow: none;
        font-size: 20px;
    }
}

.search-box {
    .form-control {
        // border-radius: 30px;
        border-radius: 0px;
        padding-left: 40px;
    }

    .search-icon {
        font-size: 16px;
        position: absolute;
        left: 13px;
        top: 0;
        line-height: 38px;
    }
}

.chat-search-box {
    .form-control {
        border: 0;
    }
}

.chat-list {
    margin: 0;
    li {
        &.active {
            a {
                background-color: var(--#{$prefix}secondary-bg);
                border-color: transparent;
                box-shadow: $box-shadow;
            }
        }
        a {
            display: block;
            padding: 14px 16px;
            color: var(--#{$prefix}secondary-color-dark);
            transition: all 0.4s;
            border-top: 1px solid var(--#{$prefix}border-color);
            border-radius: 4px;
            &:hover {
                background-color: var(--#{$prefix}secondary-bg);
                border-color: transparent;
                box-shadow: $box-shadow;
            }
        }
    }
}

.user-chat-nav {
    .dropdown {
        .nav-btn {
            color: var(--#{$prefix}body-color);
            height: 40px;
            width: 40px;
            line-height: 42px;
            box-shadow: none;
            padding: 0;
            font-size: 16px;
            background-color: var(--#{$prefix}light);
            border-radius: 50%;
            border: none;
        }

        .dropdown-menu {
            box-shadow: $box-shadow;
            border: 1px solid var(--#{$prefix}border-color);
        }
    }
}

.simplebar-vertical {
    margin: 0px;
    width: 20px;
}

.chat-conversation {
    li {
        clear: both;
    }

    .chat-day-title {
        position: relative;
        text-align: center;
        margin-bottom: 24px;

        .title {
            background-color: var(--#{$prefix}secondary-bg);
            position: relative;
            z-index: 1;
            padding: 6px 24px;
        }

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            right: 0;
            background-color: var(--#{$prefix}border-color);
            top: 10px;
        }
        .badge {
            font-size: 12px;
        }
    }
    .conversation-list {
        margin-bottom: 12px;
        display: inline-block;
        position: relative;

        .ctext-wrap {
            max-width: 500px; 
            padding: 12px 16px;
            // background-color: $primary;
            // background-color: var(--#{$prefix}gray);

            background: linear-gradient($gray-600, $gray-700);
            color: white;
            border-radius: 15px 15px 15px 0px;
            overflow: hidden;

            p {
                margin-bottom: 0.5rem;
            }
        }

        .conversation-name {
            font-weight: $font-weight-semibold;
            color: $gray-700;
            margin-bottom: 1px;
            padding: 0px 20px;
        }

        .dropdown {
            float: right;
            .dropdown-toggle {
                font-size: 18px;
                padding: 4px;
                color: var(--#{$prefix}secondary-color);
                @media (max-width: 575.98px) {
                    display: none;
                }
            }

            .dropdown-menu {
                box-shadow: $box-shadow;
                border: 1px solid var(--#{$prefix}border-color);
            }
        }

    }

    .right {
        .conversation-list {
            float: right;
            .ctext-wrap {
                background: linear-gradient($blue-500, $blue-700);
                color: white;
                // text-align: right;
                border-radius: 15px 15px 0px 15px;

                a {
                    color: white;
                }
            }
            .conversation-name {
                text-align: right;
                color: $primary;
            }
            .dropdown {
                float: left;
            }

            &.last-chat {
                .conversation-list {
                    &:before {
                        right: 0;
                        left: auto;
                    }
                }
            }
        }
    }

    .last-chat {
        .conversation-list {
            &:before {
                content: "\F0009";
                font-family: "Material Design Icons";
                position: absolute;
                color: $primary;
                right: 0;
                bottom: 0;
                font-size: 16px;

                @media (max-width: 575.98px) {
                    display: none;
                }
            }
        }
    }
}

.chat-time {
    font-size: 12px;
    font-family: $font-family-monospace;
}

.chat-input-section {
    border-top: 1px solid var(--#{$prefix}border-color);
}

.chat-input {
    border-radius: 30px;
    background-color: var(--#{$prefix}light) !important;
    border-color: var(--#{$prefix}light) !important;
    padding-right: 120px;
    font-size: large;
}

.chat-input-links {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    li {
        a {
            font-size: 20px;
            line-height: 45px;
            display: inline-block;
        }
        label > i {
            font-size: 20px;
            color: $primary;
        }
    }
    .list-inline-item:not(:last-child) {
        margin-right: 0.2rem !important;
    }
}

.chat-send {
    @media (max-width: 575.98px) {
        min-width: auto;
    }
    padding-top: 10px;
    padding-bottom: 10px;
}

.bg-offline {
    background-color: #c83232;
}

.chat-hover-show {
    // display: none;
    visibility: hidden;
}

.conversation-list {
    &:hover {
        .chat-hover-show {
            // display: inline-block;
            visibility: visible;
        }
        .ctext-wrap {
            // cursor: pointer;
            box-shadow: 0px 0px 10px $primary;
        }
    }
    .ctext-wrap {
        a {
            text-decoration: underline !important;
        }
    }

}

.simplebar-scrollbar::before {
    background: linear-gradient(darkblue, skyblue);
    width: 10px;
}